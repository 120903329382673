import { Controller } from "@hotwired/stimulus"
import * as inputs from "../src/js/input_helper"

export default class extends Controller {
  static targets = [
    "numberOfJobs",
    "job1", "job2", "job3", "job4", "job5"
  ]

  connect() {
    inputs.set_active_class_on_radios(this.numberOfJobsTarget)
    this.showHideBasedOnNumberOfJobs(Number(inputs.get_choice_from_radio_group(this.numberOfJobsTarget)))
  }

  numberOfJobsChanged(ev) {
    this.showHideBasedOnNumberOfJobs(Number(inputs.get_choice_from_radio_click(ev)))
  }

  showHideBasedOnNumberOfJobs(n_jobs) {
    this.job1Target.classList.toggle('d-none', n_jobs < 2)
    this.job2Target.classList.toggle('d-none', n_jobs < 3)
    this.job3Target.classList.toggle('d-none', n_jobs < 4)
    this.job4Target.classList.toggle('d-none', n_jobs < 5)
    this.job5Target.classList.toggle('d-none', n_jobs < 6)
  }
}
