import { Controller } from "@hotwired/stimulus"
import * as inputs from "../src/js/input_helper"
import * as utils from "../src/js/stimulus_helper"

export default class extends Controller {
  static targets = [
    "medicareCardColour",
    "medicareCardOption"
  ]

  connect() {
    const colour = inputs.get_choice_from_radio_group(this.medicareCardColourTarget)
    this.render(colour)
  }

  render(colour) {
    this.medicareCardOptionTargets.forEach(
      target => {
        if (target.dataset.value == colour) {
          utils.show(target)
        } else {
          utils.hide(target)
        }
      }
    )
  }

  medicareCardColourChanged(ev){
    this.render(inputs.get_choice_from_radio_click(ev))
  }
}
