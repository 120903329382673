import { Controller } from "@hotwired/stimulus"
import * as utils from "../src/js/stimulus_helper"
import * as inputs from "../src/js/input_helper"

export default class extends Controller {
  static targets = [
    "companyNameSelect",
    "employmentStatusSelect",
    "companyNameFreeField",
    "employerWithoutAbn",
    "employmentOngoingCheckbox",
    "endDate",
  ]

  connect() {
    this.toggleEmployerNameFields(this.employerWithoutAbnTarget.checked)
    this.employmentStatusChanged()
    this.toggleEndDate()
  }

  abnAttributeChanged() {
    this.toggleEmployerNameFields(this.employerWithoutAbnTarget.checked)
  }

  toggleEmployerNameFields(employerWithoutAbn) {
    utils.toggle(this.companyNameSelectTarget, employerWithoutAbn)
    utils.toggle(this.companyNameFreeFieldTarget, !employerWithoutAbn)
  }

  employmentStatusChanged() {
    if (this.employmentStatusSelectTarget.value == 'self_employed') {
      this.toggleEmployerNameFields(false)
      if (this.hasEmployerWithoutAbnTarget) {
        this.employerWithoutAbnTarget.disabled = true
        this.employerWithoutAbnTarget.checked = false
      }
    } else{
      if (this.hasEmployerWithoutAbnTarget) {
        this.employerWithoutAbnTarget.disabled = false
      }
    }
  }

  toggleEndDate() {
    utils.toggle(this.endDateTarget, this.employmentOngoingCheckboxTarget.checked)
  }
}
