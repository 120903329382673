import { Controller } from "@hotwired/stimulus"

export default class DiscreetSpinnerController extends Controller {
  static targets=["value","hiddenfield","top","editbox","floorRate","prescribedRate"]

  connect() {
    this.currentValue = parseFloat(this.hiddenfieldTarget.value);

    this.min = parseFloat(this.floorRateTarget.value);
    this.max = parseFloat(this.prescribedRateTarget.value);

    this.editing = false;

    this.render();
  }

  udpateEditbox() {
    this.editboxTarget.value = this.currentValue.toFixed(2);
  }

  startEditing() {
    this.udpateEditbox();
    this.topTarget.classList.add("editing");
    this.editing = true;
  }

  stopEditing() {
    this.updateFromEditbox();
    this.topTarget.classList.remove("editing")
    this.editing = false;
  }

  toggleEditing() {
    if(this.editing) {
      this.stopEditing();
    } else {
      this.startEditing();
    }
  }

  render() {
    this.valueTarget.textContent = this.currentValue.toFixed(2) + "%";
    this.hiddenfieldTarget.value = this.currentValue.toFixed(2);
  }

  newValue(v) {
    this.min = parseFloat(this.floorRateTarget.value);
    this.max = parseFloat(this.prescribedRateTarget.value);

    if(isNaN(v)) v = this.max;

    if(v < this.min) v = this.min;
    if(v > this.max) v = this.max;

    this.oldValue = this.currentValue;
    this.currentValue = v;
    this.render();

    if(this.oldValue != v) {
      let ev = new Event("change");
      this.hiddenfieldTarget.dispatchEvent(ev);
    }
  }

  mouseDown(ev) {
    const whichHalf = (ev.offsetX > ev.srcElement.offsetWidth/2);
    this.currentValue = parseFloat(this.hiddenfieldTarget.value);

    const delta = whichHalf ? 0.25 : -0.25;
    let newValue = this.currentValue + delta;

    this.newValue(newValue);
    ev.preventDefault();
    return false;
  }

  hitboxClick(ev) {
    this.toggleEditing();
    ev.preventDefault();
  }

  updateFromEditbox() {
    this.newValue(parseFloat(this.editboxTarget.value));
  }

  editboxChange(ev) {
    this.updateFromEditbox();
    this.stopEditing();
    ev.preventDefault();
    return true;
  }
}
