import { Controller } from "@hotwired/stimulus"
import * as formatter from "../src/js/format_helper"
import * as utils from "../src/js/input_helper"
import * as inputs from "../src/js/stimulus_helper"

export default class extends Controller {
  static targets = [
    "term",
    "balloonAmount",
    "totalAmount",
    "totalAmountRow",
    "ltv",
    "vehicleValue",
    "price",
    "origFee",
    "estFee",
    "ppsrFee",
    "balloonPercentage",
    "paymentAmount",
    "floorRate",
    "prescribedRate",
    "contractRate",
    "rateHasChanged",
    "rateChangeAlertMessageSection",
    "campaignCheckBox"
  ]

  connect() {
    this.handleCalculation()
  }

  handleCalculation(e) {
    let termValue = Number(this.termTarget.value)
    let balloonAmount = formatter.moneyToAmount(this.balloonAmountTarget.value)
    if (e) {
      const changedElement = e.target
      if (changedElement.id == "loan_finance_term_months") {
        // triggerd by term input
        termValue = Number(changedElement.value)
      }
      else if (changedElement.id == "loan_finance_balloon_amount") {
        // triggered by balloon input
        balloonAmount = formatter.moneyToAmount(changedElement.value)
      }
    }

    const ppsrFee = formatter.moneyToAmount(this.ppsrFeeTarget.value)
    const origFee = formatter.moneyToAmount(this.origFeeTarget.value)
    const estFee = formatter.moneyToAmount(this.estFeeTarget.value)
    const price = formatter.moneyToAmount(this.priceTarget.value)
    const rate = formatter.percentToAmount(this.contractRateTarget.value)
    const floorRate = formatter.percentToAmount(this.floorRateTarget.value)
    const prescribedRate = formatter.percentToAmount(this.prescribedRateTarget.value)
    const vehicleValue = formatter.moneyToAmount(this.vehicleValueTarget.value)

    $.post(this.data.get('url'), {
      price: price,
      term: termValue,
      balloon: balloonAmount,
      rate: rate,
      minRate: floorRate,
      maxRate: prescribedRate,
      ppsrFee: ppsrFee,
      estFee: estFee,
      origFee: origFee,
    }).done((data, textStatus, jqXHR) => {
      // balloon
      // show notice when input balloon is larger than max_balloon
      if (data['max_balloon'] < balloonAmount) {
        this.updateTitle(this.balloonAmountTarget, `The balloon has been reduced to the maximum amount of ${formatter.money(data['max_balloon'])}`)
      } else if (data['max_balloon'] == 0) {
        this.updateTitle(this.balloonAmountTarget, 'The maximum balloon amount will be $0 with this loan term')
      } else {
        this.updateTitle(this.balloonAmountTarget, '')
      }
      // update balloon input field
      this.balloonAmountTarget.value = formatter.money(data['balloon'])
      // make sure balloon_repayment_strategy is not showing when balloon amount is 0
      this.balloonPercentageTarget.textContent = formatter.percent(data['balloon_ratio'])
      // naf - total amount to be financed
      this.totalAmountTarget.value = formatter.money(data['naf'])
      // repayments
      const monthlyPayment = formatter.money(data['monthly_amount'])
      const monthlyAKF = formatter.money(data['monthly_akf'], 0)
      this.paymentAmountTarget.value = monthlyPayment
      // update the monthly payment in title
      this.updateTitle(this.paymentAmountTarget, `Contractual monthly payment amount is ${monthlyPayment} (incl. ${monthlyAKF} monthly account keeping fee)`)
      const ltv = data['ltv']
      if (ltv) {
        this.updateTitle(this.totalAmountRowTarget, `LTV is ${ltv}`)
      }
      // rate
      const formattedRate = formatter.percent(data['rate'] / 100)
      this.contractRateTarget.value = data['rate']
      this.floorRateTarget.value = data['min_rate']
      this.prescribedRateTarget.value = data['max_rate']
      this.rateHasChangedTarget.value = data['rate_has_changed']
      // term
      this.termTargets.forEach(elem => {
        elem.value = data["term"]
      })
      if (data['rate_has_changed']) {
        alert(`Please note that the rate has changed. The new rate is ${String(data['rate'])}`)
      }
    });
  }

  updateTitle(elem, title) {
    elem.setAttribute('data-original-title', title)
    $(elem).tooltip()
  }

  toggleCampaign(e) {
    document.querySelector("button[name=save]")?.click()
  }
}
