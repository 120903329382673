import { Controller } from "@hotwired/stimulus"
import * as inputs from "../src/js/input_helper"
import * as utils from "../src/js/stimulus_helper"

export default class extends Controller {
  static targets = [
    "addressSelection",
    "selectedFullAddress",
    "addressDetailsRow",
    "addressDetailsLoadingSection",
    "unitNumber",
    "streetNumber",
    "streetName",
    "streetType",
    "streetTypeCode",
    "propertyName",
    "locality",
    "state",
    "postcode",
    "timeAtAddress", "timeAtAddressUnit",
    "manualEntryRequested",
    "country"
  ]

  connect() {
    this.loadAddressSection()
    this.enableDetailsIfManualEntryRequested()
    // Select2 annoyingly doesn't fire native events, so we have to listen via a delegator
    $(this.addressSelectionTarget).on('select2:select', (e)=> this.address_selected(e))
  }

  address_selected(event) {
    const canonical_id = event.target.value
    // get the complete address from the selected option only
    var selector = `option[value="${canonical_id}"]`
    const selected_address = event.target.querySelector(selector).textContent
    if (this.hasAddressDetailsRowTarget) {
      utils.hide(this.addressDetailsRowTarget)
    }
    if (this.hasAddressDetailsLoadingSectionTarget) {
      this.addressDetailsLoadingSectionTarget.innerHTML = '<p class="col-12">Loading...</p>'
      if (this.hasAddressDetailsRowTarget){
        utils.show(this.addressDetailsRowTarget)
      }
    }
    this.selectedFullAddressTarget.value = selected_address

    fetch(this.lookup_url.replace(":addr_id", canonical_id), {
      credentials: 'same-origin'
    })
      .then(res => res.json())
      .then(res => this.update_address_details(res))
      .catch(error => console.error("Error:", error))
  }

  loadAddressSection(){
    if(this.hasTimeAtAddressTarget){
      let timeAtAddressUnit = inputs.get_choice_from_radio_group(this.timeAtAddressUnitTarget)
      let timeAtAddress = this.timeAtAddressTarget.value
      this.toggleAddressHistorySection(timeAtAddress, timeAtAddressUnit)
    }
  }

  timeAtAddressChanged(event){
    let timeAtAddress = event.target.value
    let timeAtAddressUnit = inputs.get_choice_from_radio_group(this.timeAtAddressUnitTarget)
    this.toggleAddressHistorySection(timeAtAddress, timeAtAddressUnit)
  }

  timeAtAddressUnitChanged(event){
    if (event.target.classList.contains("btn")){
      let timeAtAddressUnit = inputs.get_choice_from_radio_click(event)
      let timeAtAddress = this.timeAtAddressTarget.value
      this.toggleAddressHistorySection(timeAtAddress, timeAtAddressUnit)
    }
  }


  manualEntryRequestChanged(event){
    this.showHideAddressDetailSection(event.target.checked, !event.target.checked)
  }

  enableDetailsIfManualEntryRequested() {
    if (this.hasManualEntryRequestedTarget && this.manualEntryRequestedTarget.checked) {
      this.showHideAddressDetailSection(true, false)
    } else {
      this.showHideAddressDetailSection(false, true)
    }
  }

  toggleAddressHistorySection(timeAtAddress, unit){
    const address_history_controller = this.application.getControllerForElementAndIdentifier(this.element.closest('[data-controller="historical-addresses"]'), 'historical-addresses')
    if (address_history_controller && unit && Number(timeAtAddress)) {
      let timeAtAddressInMonths = 0
      switch (unit) {
        case "months":
          timeAtAddressInMonths = timeAtAddress
          break;
        case "years":
          timeAtAddressInMonths = timeAtAddress * 12
          break;
      }
      address_history_controller.handle_current_address_duration_change(timeAtAddressInMonths)
    }
  }

  get lookup_url() {
    return this.data.get("url")
  }

  update_address_details(api_result) {
    if (api_result['failure']) {
      if (this.hasAddressDetailsLoadingSectionTarget) {
        this.addressDetailsLoadingSectionTarget.innerHTML = api_result['failure']
      }
    } else if (this.hasAddressDetailsRowTarget) {
      this.unitNumberTarget.value = api_result['unit']
      this.streetNumberTarget.value = api_result['street_number']
      this.streetNameTarget.value = api_result['street_name']
      if (this.hasStreetTypeTarget) {
        this.streetTypeTarget.value = api_result['street_type']
      } else if (this.hasStreetTypeCodeTarget) {
        $(this.streetTypeCodeTarget).val(api_result['street_type_code']).trigger('change')
      }
      if (this.hasLocalityTarget) {
        this.localityTarget.value = api_result['locality']
      }

      $(this.stateTarget).val(api_result['state']).trigger('change')
      this.postcodeTarget.value = api_result['postcode']
      if (this.hasCountryTarget) {
        this.countryTarget.value = api_result['country']
      }

      this.enableDetailsIfManualEntryRequested()
    }
  }

  showHideAddressDetailSection(forceShow, readOnly) {
    const shouldShow = forceShow ||
      (this.hasLocalityTarget && this.localityTarget.value) ||
      (this.hasPostCodeTarget && this.postcodeTarget.value)

    if (shouldShow) {
      this.toggleReadonlyForElements(readOnly)

      if (this.hasAddressDetailsRowTarget) {
        utils.show(this.addressDetailsRowTarget)
      }
    } else if (this.hasAddressDetailsRowTarget) {
      utils.hide(this.addressDetailsRowTarget)
    }

    utils.hide(this.addressDetailsLoadingSectionTarget)
  }

  toggleReadonlyForElements(readOnly) {
    const elements = this.targets.findAll(
      'streetNumber',
      'streetName',
      'streetType',
      'streetTypeCode',
      'locality',
      'state',
      'postcode',
      'country'
    )

    elements.forEach(element => {
      if (readOnly) {
        element.setAttribute("readonly", "")
      } else {
        element.removeAttribute("readonly")
      }
    })
  }
}
